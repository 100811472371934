import $ from 'jquery';
import {Pagination, Autoplay, Mousewheel, EffectFade, Navigation} from 'swiper/modules';
import {Swiper} from 'swiper'
import 'swiper/css';

Swiper.use([Pagination, Autoplay, Mousewheel, EffectFade, Navigation])

let missionSlider = $('.js-mission-slider');

if (missionSlider.length > 0) {
  missionSlider.each(function () {
    let missionSliderSwiper = new Swiper(this, {
      slidesPerView: "auto",
      loop: false,
      centeredSlides: true,
      centeredSlidesBounds: true,

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    missionSliderSwiper.init();
  });
}

